import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { StartPage, ValidatePage } from '../pages';

export const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path='/start/:code' element={<StartPage />} />

      <Route path={'/validate'} element={<ValidatePage />} />

      <Route path='/' element={<Navigate to='/validate' />} />
      <Route path='*' element={<Navigate to='/validate' />} />
    </Routes>
  );
};
