import { Button, Grid } from '@mui/material';
import { ClientService } from '../../../../../shared/services/api/client/ClientService';

import { useCallback, useEffect, useRef, useState } from 'react';
import AuthCode, { AuthCodeRef } from 'react-auth-code-input';
import { Section } from '../../../../../shared/components';
import { feedback } from '../../../../../shared/services';
import { ApiException } from '../../../../../shared/services/api/ApiException';
import {
  ISign,
  SignService,
} from '../../../../../shared/services/api/sign/SignService';
import style from './auth.module.css';

export const TokenStep = (props: any) => {
  const [count, setCount] = useState(60);
  const timerRef = useRef<number | null>(null);

  const startTimer = useCallback(() => {
    if (timerRef.current === null) {
      timerRef.current = window.setInterval(countDown, 1000);
    }
  }, []);

  const countDown = () => {
    setCount((prevCount) => {
      if (prevCount <= 1) {
        clearInterval(timerRef.current!);
        timerRef.current = null;
        return 0;
      }
      return prevCount - 1;
    });
  };

  const resendToken = () => {
    let hash = window.location.pathname.replace('/start/', '');
    let dadosValidados = JSON.parse(localStorage.getItem('data') || '{}');

    feedback('Aguarde, enviando token ...', 'loading');
    setCount(60);
    startTimer();

    ClientService.putClient(hash, dadosValidados)
      .then(() => {
        feedback(
          'Verifique seu e-mail com o token de autenticação!',
          'success'
        );
      })
      .catch((error) => {
        if (error instanceof ApiException) {
          feedback(error.message, 'error');
        }
      });
  };

  const AuthInputRef = useRef<AuthCodeRef>(null);

  const handleOnChange = (res: any) => {
    if (res.length < 6) {
      return;
    }

    let hash = window.location.pathname.replace('/start/', '');
    let data: ISign = {
      ip: props.ip,
      latitude: props.latitude,
      longitude: props.longitude,
      token: res,
    };

    feedback('Aguarde, validando token ...', 'loading');

    SignService.putSign(hash, data)
      .then(() => {
        feedback('Documento assinado com sucesso!', 'success');
        props.aoAssinar();
        props.next();
      })
      .catch((error) => {
        if (error instanceof ApiException) {
          feedback(error.message, 'error');
        }
      });
  };

  useEffect(() => {
    startTimer();

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [startTimer]);

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '10px 10px',
        }}
      >
        <Section title='Token de autenticação' margin>
          Foi enviado para seu e-mail um token.
          <br />
          <br />
          <label>
            <b>Informe o token</b>
          </label>
          <AuthCode
            inputClassName={style.inputAuth}
            autoFocus={true}
            onChange={handleOnChange}
            ref={AuthInputRef}
            allowedCharacters='numeric'
          />
          {count > 0 ? (
            <Button size='large' disabled>
              Reenviar token ({count})
            </Button>
          ) : (
            <Button size='large' onClick={resendToken}>
              Reenviar token
            </Button>
          )}
        </Section>
      </Grid>
    </Grid>
  );
};
