import * as React from 'react';
import { memo, useCallback, useEffect, useState } from 'react';

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Skeleton,
  TextField,
} from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import InputMask from 'react-input-mask';
import { Section } from '../../../../shared/components';
import { feedback } from '../../../../shared/services';
import { ApiException } from '../../../../shared/services/api/ApiException';
import { ClientService } from '../../../../shared/services/api/client/ClientService';
import { DocumentService } from '../../../../shared/services/api';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  nome: Yup.string().required('Campo obrigatório').min(3).max(100),
  cpf: Yup.string()
    .required('Campo obrigatório')
    .test('cpf', 'CPF inválido', function (value) {
      if (!value) return false;
      const cpfLimpo = value.replace(/\D/g, '');
      if (cpfLimpo.length === 0) {
        return false;
      }
      if (cpfLimpo.length !== 11) {
        return false;
      }
      if (Array.from(cpfLimpo).every((value) => value === cpfLimpo[0])) {
        return false;
      }
      return true;
    }),
  dataNascimento: Yup.string().required('Campo obrigatório'),
  email: Yup.string().email('E-mail inválido').required('Campo obrigatório'),
});

type ClientFormSchema = Yup.InferType<typeof validationSchema>;

export interface IDataConfirmStep {
  code: string;
  next: any;
  back: any;
}

export const DataConfirmStep = memo(
  ({ back, code, next }: IDataConfirmStep) => {
    const [exibirConfirmaçao, setExibirConfirmacao] = useState(false);
    const [desabilitarCampos, setDesabilitarCampos] = useState(false);
    const [confirmarDados, setConfirmarDados] = useState(false);

    const [isLoading, setIsLoading] = useState(true);

    const formik = useFormik<ClientFormSchema>({
      initialValues: {
        cpf: '',
        dataNascimento: '',
        email: '',
        nome: '',
      },
      validationSchema,
      onSubmit: async (data) => {
        try {
          feedback('Aguarde, confirmando dados ...', 'loading');

          let hash = window.location.pathname.replace('/start/', '');

          localStorage.setItem('data', JSON.stringify(data));

          await ClientService.putClient(hash, data);
          feedback(
            'Verifique seu e-mail com o token de autenticação!',
            'success'
          );
          next();
        } catch (error) {
          if (error instanceof ApiException) {
            const key = Object.keys(error.errors);
            const message = Object.values(error.errors);
            feedback(error.message, 'error');
            if (key.toString() === 'nome') {
              formik.setFieldError('nome', message.toString());
            }
            if (key.toString() === 'email') {
              formik.setFieldError('email', message.toString());
            }
          }
        }
      },
    });

    const getDocument = useCallback(async () => {
      try {
        const data = await DocumentService.getDocumentoByCode(code);
        formik.setValues({
          cpf: data?.cpf,
          dataNascimento: data?.dataNascimento
            ? moment(data?.dataNascimento).format('YYYY-MM-DD')
            : '',
          email: data?.email,
          nome: data?.nome,
        });

        if (
          data &&
          data?.cpf &&
          data?.email &&
          data?.nome &&
          data?.dataNascimento
        ) {
          setDesabilitarCampos(true);
          setExibirConfirmacao(true);
          setConfirmarDados(false);
        }
      } catch (error) {
        if (error instanceof ApiException) {
          feedback(error.message, 'error');
          setExibirConfirmacao(false);
        }
      } finally {
        setIsLoading(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code]);

    useEffect(() => {
      getDocument();
    }, [getDocument]);

    useEffect(() => {
      if (!exibirConfirmaçao) {
        setConfirmarDados(true);
      }
    }, [exibirConfirmaçao]);

    const handleConfirmarDados = (event) => {
      setConfirmarDados(event.target.checked);
    };

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Section title='Confirme seus dados' margin padding>
            <Box component='form' onSubmit={formik.handleSubmit}>
              <FormControl margin='none' fullWidth>
                <FormLabel htmlFor='nome'>Nome completo</FormLabel>
                {!isLoading && (
                  <TextField
                    id='nome'
                    name='nome'
                    disabled={desabilitarCampos}
                    value={formik.values.nome}
                    variant='outlined'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    size='medium'
                    error={
                      (formik.touched.nome && !!formik.errors.nome) ||
                      (formik.submitCount > 0 && !formik.values.nome)
                    }
                    helperText={
                      (formik.touched.nome && !!formik.errors.nome) ||
                      (formik.submitCount > 0 && !formik.values.nome)
                        ? formik.errors.nome
                        : ''
                    }
                    fullWidth
                  />
                )}
                {isLoading && (
                  <Skeleton width='100%' height='38px' variant='rounded' />
                )}
              </FormControl>

              <FormControl margin='none' fullWidth>
                <FormLabel htmlFor='email'>E-mail</FormLabel>
                {!isLoading && (
                  <TextField
                    id='email'
                    name='email'
                    disabled={desabilitarCampos}
                    value={formik.values.email}
                    variant='outlined'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    size='medium'
                    error={
                      (formik.touched.email && !!formik.errors.email) ||
                      (formik.submitCount > 0 && !formik.values.email)
                    }
                    helperText={
                      (formik.touched.email && !!formik.errors.email) ||
                      (formik.submitCount > 0 && !formik.values.email)
                        ? formik.errors.email
                        : ''
                    }
                    fullWidth
                  />
                )}
                {isLoading && (
                  <Skeleton width='100%' height='38px' variant='rounded' />
                )}
              </FormControl>

              <FormControl margin='none' fullWidth>
                <FormLabel htmlFor='cpf'>CPF</FormLabel>
                {!isLoading && (
                  <InputMask
                    disabled={desabilitarCampos}
                    id='cpf'
                    name='cpf'
                    value={formik.values.cpf}
                    mask='999.999.999-99'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      formik.setFieldValue(
                        'cpf',
                        e.target.value
                          .replace('.', '')
                          .replace('.', '')
                          .replace('-', '')
                      )
                    }
                    onBlur={formik.handleBlur}
                  >
                    <TextField
                      fullWidth
                      variant='outlined'
                      id='cpf'
                      name='cpf'
                      size='medium'
                      error={
                        (formik.touched.cpf && !!formik.errors.cpf) ||
                        (formik.submitCount > 0 && !formik.values.cpf)
                      }
                      helperText={
                        (formik.touched.cpf && !!formik.errors.cpf) ||
                        (formik.submitCount > 0 && !formik.values.cpf)
                          ? formik.errors.cpf
                          : ''
                      }
                    />
                  </InputMask>
                )}
                {isLoading && (
                  <Skeleton width='100%' height='38px' variant='rounded' />
                )}
              </FormControl>

              <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='pt-BR'>
                <FormControl margin='none' fullWidth>
                  <FormLabel htmlFor='dataNascimento'>
                    Data de nascimento
                  </FormLabel>
                  {!isLoading && (
                    <DatePicker
                      disabled={desabilitarCampos}
                      value={
                        formik.values.dataNascimento
                          ? moment(formik.values.dataNascimento)
                          : null
                      }
                      format='DD/MM/YYYY'
                      disableFuture
                      slotProps={{
                        field: { clearable: true },
                        textField: {
                          id: 'dataNascimento',
                          name: 'dataNascimento',
                          fullWidth: true,
                          variant: 'outlined',
                          error:
                            (formik.touched.dataNascimento &&
                              !!formik.errors.dataNascimento) ||
                            (formik.submitCount > 0 &&
                              !formik.values.dataNascimento),
                          helperText:
                            (formik.touched.dataNascimento &&
                              !!formik.errors.dataNascimento) ||
                            (formik.submitCount > 0 &&
                              !formik.values.dataNascimento)
                              ? formik.errors.dataNascimento
                              : '',
                          onBlur: formik.handleBlur,
                        },
                      }}
                      openTo='day'
                      onChange={(date: moment.Moment | null) => {
                        formik.setFieldValue(
                          'dataNascimento',
                          date ? moment(date).format('YYYY-MM-DD') : null
                        );
                      }}
                    />
                  )}
                  {isLoading && (
                    <Skeleton width='100%' height='38px' variant='rounded' />
                  )}
                </FormControl>
              </LocalizationProvider>
              <FormControl
                margin='none'
                sx={{ display: exibirConfirmaçao ? 'flex' : 'none' }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={confirmarDados}
                      onChange={handleConfirmarDados}
                    />
                  }
                  label='Confirmar dados'
                />
              </FormControl>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: 2,
                    textAlign: 'right',
                    backgroundColor: '#fff',
                    padding: '10px',
                    width: '100%',
                  }}
                >
                  {!isLoading && (
                    <Button
                      variant='outlined'
                      size='large'
                      type='button'
                      onClick={back}
                    >
                      Voltar
                    </Button>
                  )}
                  {isLoading && (
                    <Skeleton width='100px' height='42px' variant='rounded' />
                  )}
                  {!isLoading && (
                    <Button
                      disabled={confirmarDados ? formik.isSubmitting : true}
                      variant='contained'
                      size='large'
                      type='submit'
                    >
                      Próximo
                    </Button>
                  )}
                  {isLoading && (
                    <Skeleton width='100px' height='42px' variant='rounded' />
                  )}
                </Box>
              </Grid>
            </Box>
          </Section>
        </Grid>
      </Grid>
    );
  }
);
