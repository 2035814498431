import DrawIcon from '@mui/icons-material/Draw';
import 'react-loading-skeleton/dist/skeleton.css';
import { feedback } from '../../shared/services';
import { ApiException } from '../../shared/services/api/ApiException';
import {
  Box,
  Button,
  Grid,
  Skeleton,
  Step,
  StepLabel,
  Stepper,
} from '@mui/material';
import { memo, useCallback, useEffect, useState } from 'react';

import moment from 'moment';
import { useGeolocated } from 'react-geolocated';
import { PageLayout } from '../../shared/layouts';
import {
  DocumentService,
  ISignatario,
  StatusDocumento,
  StatusDocumentoSignatario,
} from '../../shared/services/api/document/DocumentService';
import { DataConfirmStep } from './components/steps/DataConfirmStep';
import { ViewerStep } from './components/steps/viewer/ViewerStep';
import { TokenStep } from './components/steps/token/TokenStep';
import { ErrorStep } from './components/steps/ErrorStep';
import { AccordionDocumento } from './components/AccordionDocumento';
import { StatusStep } from './components/steps/StatusStep';
import { AccordionSignatario } from './components/AccordionSignatario';

const publicIp = require('react-public-ip');

enum Steps {
  DOCUMENTO,
  CONFIRMAR_DADOS,
  ASSINAR,
  FINALIZADO,
}

const steps = [
  {
    key: Steps.DOCUMENTO,
    label: 'Documento',
    page: ViewerStep,
  },
  {
    key: Steps.CONFIRMAR_DADOS,
    label: 'Confirmar Dados',
    page: DataConfirmStep,
  },
  {
    key: Steps.ASSINAR,
    label: 'Assinar',
    page: TokenStep,
  },
];

export const StartPage = memo(() => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [activeStep, setActiveStep] = useState(Steps.DOCUMENTO);
  const [ip, setIp] = useState();
  const [code, setCode] = useState('');
  const [tipoDocumento, setTipoDocumento] = useState('');
  const [nome, setNome] = useState('');
  const [signatarios, setSignatarios] = useState<ISignatario[]>([]);
  const [dataValidade, setDataValidade] = useState('');
  const [pdf, setPdf] = useState('');

  const [statusDocumento, setStatusDocumento] =
    useState<StatusDocumento | null>(null);

  const larguraDaTela =
    window.innerWidth ||
    window.document.documentElement.clientWidth ||
    window.document.body.clientWidth;
  const isMobileDevice = larguraDaTela <= 600;

  const [expandirDocumento, setExpandirDocumento] = useState(!isMobileDevice);
  const [expandirSignatario, setExpandirSignatario] = useState(!isMobileDevice);

  const handleExpandirDocumento = () => {
    setExpandirDocumento((state) => !state);
  };

  const handleExpandirSignatario = () => {
    setExpandirSignatario((state) => !state);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = useCallback(() => {
    if (activeStep) setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }, [activeStep]);

  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
    }) || { latitude: '0', longitude: '0' };

  const ipPublic = async () => {
    setIp((await publicIp.v4()) || '');
  };

  const coord: [number, number] = [
    coords?.longitude || 0,
    coords?.latitude || 0,
  ];

  const getDocument = useCallback(async () => {
    let hash = window.location.pathname.replace('/start/', '');
    setIsLoading(true);
    try {
      const response = await DocumentService.getDocument(hash);
      setNome(response.data.nome);
      setTipoDocumento(
        'Assinatura Eletrônica do ' + response.data.tipoDocumento
      );
      setDataValidade(response.data.dataValidade);
      setSignatarios(response.data.signatarios);
      setStatusDocumento(response.data.status);

      if (
        response.data.statusSignatario === StatusDocumentoSignatario.ASSINADO ||
        response.data.status === StatusDocumento.ASSINADO
      ) {
        setActiveStep(Steps.FINALIZADO);
      }

      setError(false);
    } catch (error) {
      if (error instanceof ApiException) {
        feedback(error.message, 'error');
        setError(true);
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  const aoAssinar = useCallback(() => {
    getDocument();
  }, [getDocument]);

  useEffect(() => {
    if (code) {
      getDocument();
    }
  }, [getDocument, code]);

  useEffect(() => {
    ipPublic();
    let hash = window.location.pathname.replace('/start/', '');
    setCode(hash);
    localStorage.setItem('code', hash);

    DocumentService.getDocumentFile(hash)
      .then((result) => {
        const blob = new Blob([result], { type: 'application/pdf' });

        const pdfUrl = URL.createObjectURL(blob);
        setPdf(pdfUrl);
      })
      .catch((error) => {
        if (error instanceof ApiException) {
          feedback(error.message, 'error');
        }
      });
  }, []);

  const RenderComponent = useCallback(
    ({ item: { activeStep } }) => {
      switch (activeStep) {
        case Steps.DOCUMENTO:
          return <ViewerStep pdf={pdf} code={code} />;
        case Steps.CONFIRMAR_DADOS:
          return (
            <DataConfirmStep back={handleBack} next={handleNext} code={code} />
          );
        case Steps.ASSINAR:
          return (
            <TokenStep
              next={handleNext}
              back={handleBack}
              ip={ip}
              latitude={coords?.latitude.toString()}
              longitude={coords?.longitude.toString()}
              aoAssinar={aoAssinar}
            />
          );
        case Steps.FINALIZADO:
          return <StatusStep status={StatusDocumento.ASSINADO} />;
        default:
          return <ErrorStep />;
      }
    },
    [pdf, handleBack, code, coords, ip, aoAssinar]
  );

  return (
    <PageLayout title={tipoDocumento}>
      <Grid
        container
        spacing={3}
        gap={!isMobileDevice ? 3 : 0}
        maxWidth={'100%'}
        minWidth={'100%'}
        height={'100%'}
        display='flex'
        alignItems='flex-start'
        justifyContent='center'
        border={0}
        sx={{
          marginLeft: '0 !important',
        }}
      >
        <Grid
          item
          xs={12}
          lg={3}
          sx={{
            paddingLeft: '0 !important',
            paddingTop: `${isMobileDevice ? '0 !important' : ''}`,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              padding: '8px',
              gap: 2,
            }}
          >
            <AccordionDocumento
              dataValidade={dataValidade}
              error={error}
              nome={nome}
              expandirDocumento={expandirDocumento}
              handleExpandirDocumento={handleExpandirDocumento}
            />
            <AccordionSignatario
              coord={coord}
              coords={coords}
              error={error}
              isGeolocationAvailable={isGeolocationAvailable}
              isGeolocationEnabled={isGeolocationEnabled}
              loading={isLoading}
              signatarios={signatarios}
              expandirSignatario={expandirSignatario}
              handleExpandirSignatario={handleExpandirSignatario}
              larguraDaTela={larguraDaTela}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          lg={8}
          sx={{ paddingBottom: '20px', paddingLeft: '0 !important' }}
        >
          {statusDocumento !== StatusDocumento.VENCIDO &&
            statusDocumento !== StatusDocumento.CANCELADO && (
              <>
                <Stepper activeStep={activeStep}>
                  {error && <></>}

                  {!error &&
                    steps.map((item) => (
                      <Step key={item.key}>
                        {!isLoading && <StepLabel>{item.label}</StepLabel>}
                        {isLoading && (
                          <Skeleton
                            variant='text'
                            width='120px'
                            height='100%'
                          />
                        )}
                      </Step>
                    ))}
                </Stepper>
                <Box
                  sx={{
                    display: 'flex',
                    alignContent: 'center',
                    padding: '16px 0 32px',
                    justifyContent: 'center',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <RenderComponent item={{ activeStep }} />
                </Box>
              </>
            )}
          {statusDocumento === StatusDocumento.VENCIDO && (
            <StatusStep status={StatusDocumento.VENCIDO} />
          )}
          {statusDocumento === StatusDocumento.CANCELADO && (
            <StatusStep status={StatusDocumento.CANCELADO} />
          )}
          {activeStep === Steps.DOCUMENTO &&
            !isLoading && !!pdf &&
            moment(dataValidade).isAfter(moment.now()) && (
              <Grid item xs={12} display='flex' justifyContent='flex-end'>
                <Box
                  sx={{
                    position: 'fixed',
                    bottom: 0,
                    right: 0,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignContent: 'flex-end',
                    width: '100%',
                    padding: '10px',
                    backgroundColor: '#fff',
                    boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.2)',
                  }}
                >
                  <Button
                    variant='contained'
                    size='large'
                    startIcon={<DrawIcon />}
                    onClick={handleNext}
                    fullWidth={isMobileDevice}
                  >
                    Assinar
                  </Button>
                </Box>
              </Grid>
            )}
        </Grid>
      </Grid>
    </PageLayout>
  );
});
