import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React from 'react';

import { useDrawer } from '../hooks';

const useClasses = makeStyles((theme: Theme) => ({
  content: {
    flexGrow: 1,
    display: 'flex',
    height: '100vh',
    flexDirection: 'column',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

export const LayoutBase: React.FC = ({ children }) => {
  const { isOpen, isDrawerPersistent, drawerWidth } = useDrawer();
  const classes = useClasses();

  return (
    <>
      <main style={{ marginLeft: isDrawerPersistent ? drawerWidth : 0 }} className={clsx(classes.content, { [classes.contentShift]: (!isOpen || !isDrawerPersistent) })}>
        {children}
      </main>
    </>

  );
};


