import React from 'react';
import MuiAlert, { AlertProps } from '@mui/lab/Alert';
import { CircularProgress, Container, Snackbar } from '@mui/material';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface BasicSnackBarProps {
  isOpen: boolean;
  message: string;
  onClose?(): void;
  actionOnClick?(): void;
  type: 'error' | 'warning' | 'info' | 'success' | 'loading' | undefined;
}
export const BasicSnackBar: React.FC<BasicSnackBarProps> = ({ message, isOpen, actionOnClick, onClose, type }) => {

  setTimeout(() => {
    onClose && onClose();
  }, type !== 'loading' ? 6000 : 99999999);

  return (
    <Snackbar open={isOpen} autoHideDuration={type !== 'loading' ? 6000 : 99999999} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} onClose={onClose}>
      <Container>
        <Alert
          icon={type !== 'loading' ? undefined : <CircularProgress size={20} />}
          severity={type !== 'loading' ? type : 'success'}
          onClick={actionOnClick}
          children={message}
          onClose={onClose}
        />
      </Container>
    </Snackbar>
  );
};
