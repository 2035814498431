import {
  AppBar,
  Box,
  Grid,
  Paper,
  Theme,
  Toolbar,
  Typography,
} from '@mui/material';
import React, { memo } from 'react';

import { makeStyles } from '@mui/styles';

const useClasses = makeStyles((theme: Theme) => ({
  toolbar: {
    padding: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& > .MuiGrid-root.MuiGrid-container': {
      margin: 0,
      padding: 0,
    },

    '& > .MuiGrid-root.MuiGrid-item': {
      padding: 0,
    },
  },
}));

interface PageLayoutProps {
  /** Titulo da página atual */
  title: string;
  /** Usado para adiconar uma barra extra de navegação */
  navigation?: React.ReactNode;
  /** Alinha o layout flex contido no interior do componente */
  alignNavigation?: 'end' | 'start';
}

/**
 * Usado para definir uma estrutura básica para todas as páginas
 * @param title Titulo da página atual
 * @param tools Ferramentas que estarão no app bar
 * @param alignTools alinhamento das ferramentas
 * @param children Conteúdo da página ex: Forms, Inputs, Labels, Grids...
 */
export const PageLayout: React.FC<PageLayoutProps> = memo(
  ({ title, alignNavigation, navigation, children }) => {
    const classes = useClasses();
    return (
      <>
        <AppBar color='default' position='sticky'>
          <Box>
            <Toolbar className={classes.toolbar}>
              <Grid container spacing={2} className={classes.toolbar}>
                <Grid item xs={6} display='flex' alignItems='center'>
                  <img
                    alt='logo gvdasa'
                    src='../Logo-GVsign.png'
                    style={{ width: '78px', height: '30px' }}
                  />
                </Grid>
                <Grid item xs={6} display='flex' justifyContent='flex-end'>
                  <Typography>{title}</Typography>
                </Grid>
              </Grid>
            </Toolbar>
          </Box>

          {navigation && (
            <Box // Barra de navegação
              padding={0}
              display='flex'
              paddingLeft={2}
              paddingRight={2}
              boxShadow='none'
              borderRadius='0px'
              component={Paper}
              alignItems='center'
              justifyContent={`flex-${alignNavigation}`}
            >
              {navigation}
            </Box>
          )}
        </AppBar>

        <Box
          padding={2}
          paddingTop={4}
          display='flex'
          flexDirection='column'
          flex={1}
          overflow='hidden'
        >
          <Paper
            variant='outlined'
            component={Box}
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              border: '0px',
            }}
          >
            {children}
          </Paper>
        </Box>
      </>
    );
  }
);
