import { useCallback, useState } from 'react';
import SkeletonComponent from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Box, Grid, styled } from '@mui/material';
import { GvPdfViewer } from './GvPdfViewer';
import { DocumentService } from '../../../../../shared/services/api';
import { ApiException } from '../../../../../shared/services/api/ApiException';
import { feedback } from '../../../../../shared/services';

const Skeleton = styled(SkeletonComponent)({
  height: 800,
  zIndex: 0,
});

interface ViewerStepProps {
  pdf: string;
  code: string;
}

export const ViewerStep = ({ pdf, code }: ViewerStepProps) => {
  const [downloading, setDownloading] = useState(false);

  const larguraDaTela =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  const isMobileDevice = larguraDaTela <= 600;

  const handleDownloadDocument = useCallback(async () => {
    try {
      setDownloading(true);
      const { data } = await DocumentService.getDocument(code);
      const a = window.document.createElement('a');
      a.style.display = 'none';
      a.href = pdf;
      a.download = data.nome;
      a.click();
      window.document.body.appendChild(a);
      window.URL.revokeObjectURL(pdf);
    } catch (error) {
      if (error instanceof ApiException) {
        feedback(error.message, 'error');
      }
    } finally {
      setDownloading(false);
    }
  }, [code, pdf]);

  return (
    <Grid container spacing={2}>
      {!isMobileDevice && (
        <Grid item xs={12}>
          {pdf === '' ? (
            <Skeleton />
          ) : pdf !== '' ? (
            <>
              <Box
                width='100%'
                height='100%'
                justifyContent='center'
                alignItems='center'
              >
                <GvPdfViewer
                  pdf={pdf}
                  onDownload={handleDownloadDocument}
                  downloading={downloading}
                />
              </Box>
            </>
          ) : (
            'Nenhum pdf gerado...'
          )}
        </Grid>
      )}
      {isMobileDevice && (
        <Grid item xs={12}>
          {pdf === '' ? (
            <Skeleton />
          ) : pdf !== '' ? (
            <>
              <Box
                width='100%'
                height='100%'
                padding='8px'
                display='flex'
                justifyContent='center'
                alignItems='center'
              >
                <GvPdfViewer
                  pdf={pdf}
                  onDownload={handleDownloadDocument}
                  downloading={downloading}
                />
              </Box>
            </>
          ) : (
            'Nenhum pdf gerado...'
          )}
        </Grid>
      )}
    </Grid>
  );
};
