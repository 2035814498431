import { Environment } from '../../../environment';
import { Api } from '../../axios-config/AxiosConfig';
import { ApiException } from '../ApiException';

export interface ISign {
  token: string;
  ip: string;
  latitude: string;
  longitude: string;
}

const putSign = async (code: string, data: ISign): Promise<void> => {
  try {
    await Api.put(
      `${Environment.API_GATEWAY}/api/v1/Documentos/${code}/assinar`,
      data,
      {
        headers: {
          idTenant: 'gvdasa-cloud',
        },
      }
    );
  } catch (error: any) {
    throw new ApiException(
      error.data?.data?.erro ?? 'Não foi possível finalizar a requisição.'
    );
  }
};

export const SignService = {
  putSign,
};
