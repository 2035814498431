import { Environment } from '../../../environment';
import { Api } from '../../axios-config/AxiosConfig';
import { ApiException } from '../ApiException';
import moment from 'moment/moment';

export interface IClient {
  nome: string;
  cpf: string;
  dataNascimento: string;
  email: string;
}

const putClient = async (code: string, data: IClient): Promise<void> => {
  data.dataNascimento = moment(data.dataNascimento).format('YYYY-MM-DD');
  try {
    await Api.put(
      `${Environment.API_GATEWAY}/api/v1/Documentos/${code}/enviarToken`,
      data,
      {
        headers: {
          idTenant: 'gvdasa-cloud',
        },
      }
    );
  } catch (error: any) {
    throw new ApiException(
      error.data.data.erro || 'Não foi possível finalizar a requisição'
    );
  }
};

export const ClientService = {
  putClient,
};
