import React, { useCallback, useEffect, useState } from 'react';
import { CircularProgress, Container, Snackbar } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/lab/Alert';

import { AlertService, AlertTypes } from './../../services';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface IAlertComponentState {
  isOpen: boolean;
  message: string;
  /** "error" | "warning" | "info" | "success" | "loading" */
  type: AlertTypes;
  onClose?: Function;
}

export const AlertComponent: React.FC = () => {
  const [alert, setAlert] = useState<IAlertComponentState>({
    type: undefined,
    isOpen: false,
    message: ''
  });

  // Configura a inscrição no serviço de mensagens
  useEffect(() => {
    const subscription = AlertService.subscribe(msg => setAlert({ message: msg.message, type: msg.type, isOpen: true, onClose: msg.onClose }));
    return () => {
      subscription.unsubscribe();
      setAlert({ message: '', type: undefined, isOpen: false });
    };
  }, []);

  // Quando necessário fecha a mensagem
  const handleOnCloseAlert = useCallback(() => {
    setAlert({
      ...alert,
      isOpen: false,
      type: undefined,
    });
    alert.onClose && alert.onClose();
  }, [setAlert, alert]);

  // Configura o desaparecimento da mensagem
  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (alert.isOpen && alert.type !== 'loading') {
      timer = setTimeout(handleOnCloseAlert, 6000);
      return () => clearTimeout(timer);
    }

  }, [alert, handleOnCloseAlert]);

  return (
    <Snackbar
      open={alert.isOpen}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Container>
        <Alert
          children={alert.message}
          onClose={handleOnCloseAlert}
          action={alert.type !== 'loading' ? null : <></>}
          severity={alert.type !== 'loading' ? alert.type : 'info'}
          icon={alert.type !== 'loading' ? undefined : <CircularProgress color="inherit" size={20} />}
        />
      </Container>
    </Snackbar>
  );
};
