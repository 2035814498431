import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import { Box, Grid } from '@mui/material';
import { Section } from '../../../../shared/components';

export const ErrorStep = () => {
  return (
    <Grid container spacing={2}>
      <Grid
        item
        sm={12}
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          margin: '10px 10px',
        }}
      >
        <Box
          maxWidth='600px'
          sx={{
            border: '1px solid #CCC',
            margin: 'auto',
            borderRadius: '5px',
          }}
          justifyContent='center'
          alignItems='center'
        >
          <Section title='Verificador de Autenticidade' margin>
            <h2 style={{ display: 'flex', alignItems: 'center' }}>
              {' '}
              <GppMaybeIcon
                style={{ width: '50px', height: '50px' }}
                color='error'
              ></GppMaybeIcon>
              &nbsp;Alerta!
            </h2>
            <h3>Náo foi possível carregar o documento.</h3>
            <p>
              Verifique se o link enviado por e-mail ainda é válido ou contate o
              suporte para informações. Equipe <b>GVdasa</b>.
            </p>
          </Section>
        </Box>
      </Grid>
    </Grid>
  );
};
