import core from '@gvdasa/gv-core';
import { CssBaseline } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';

import { AppRoutes } from './routes';
import { AlertComponent, ConfirmationDialogComponent } from './shared/components';
import { ThemesProvider } from './shared/contexts';
import { Environment } from './shared/environment';

import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-br');

export const App = () => {
  if (Environment.APP_INSIGHTS_CONNECTION_STRING !== '') {
    core.GVObservable(Environment.APP_INSIGHTS_CONNECTION_STRING);
  }

  return (
    <>
      <CssBaseline />
      <ThemesProvider>
        <BrowserRouter>
          <AppRoutes />
          <AlertComponent />
          <ConfirmationDialogComponent />
        </BrowserRouter>
      </ThemesProvider>
    </>
  );
};
