
import { createTheme } from '@mui/material/styles';
import { ptBR } from '@mui/x-date-pickers';

export const LightTheme = createTheme({
  palette: {
    primary: {
      main: '#018781',
      light: '#339F9A',
      dark: '#005E5A',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#01B9AD ',
      light: '#33C7BD',
      dark: '#008179',
      contrastText: '#FFFFFF',
    },
    error: {
      main: '#F50000',
      light: '#F73333',
      dark: '#AB0000',
    },
    warning: {
      main: '#FFAC33',
      light: '#FF9800',
      dark: '#B26A00',
    },
    success: {
      main: '#33B673',
      light: '#00A451',
      dark: '#007238',
    },
    info: {
      main: '#018781',
      light: '#339F9A',
      dark: '#005E5A',
    },
    text: {
      primary: '#414141',
    },
    background: {
      default: '#F8F8F8',
      paper: '#FFFFFF',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        size: 'small'
      },
      styleOverrides: {
        root: {
          borderRadius: '4px',
        }
      }
    },
    MuiFilledInput: {
      defaultProps: {
        margin: 'dense',
      }
    },
    MuiFormControl: {
      defaultProps: {
        margin: 'dense',
      }
    },
    MuiFormHelperText: {
      defaultProps: {
        margin: 'dense',
      }
    },
    MuiIconButton: {
      defaultProps: {
        size: 'small',
      }
    },
    MuiInputBase: {
      defaultProps: {
        margin: 'dense',
      }
    },
    MuiInputLabel: {
      defaultProps: {
        margin: 'dense',
      }

    },
    MuiListItem: {
      defaultProps: {
        dense: true,
      }
    },
    MuiOutlinedInput: {
      defaultProps: {
        margin: 'dense',
        size: 'small'
      },
      styleOverrides: {
        root: {
          borderRadius: '4px',
        }
      }
    },
    MuiFab: {
      defaultProps: {
        size: 'small',
      }
    },
    MuiTable: {
      defaultProps: {
        size: 'small',
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
        }
      }
    },
    MuiToolbar: {
      defaultProps: {
        variant: 'dense',
      }
    },
    MuiIcon: {
      defaultProps: {
        fontSize: 'small'

      }
    },
  },
},ptBR);
