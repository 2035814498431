import VerifiedIcon from '@mui/icons-material/Verified';
import { Box, Button, Grid } from '@mui/material';
import moment from 'moment';
import { Section } from '../../shared/components';
import { PageLayout } from '../../shared/layouts';

import React from 'react';
import { feedback } from '../../shared/services';
import { ApiException } from '../../shared/services/api/ApiException';
import { DocumentService } from '../../shared/services/api/document/DocumentService';

export const ValidatePage = () => {
  const [loading, setIsLoading] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  const [name, setName] = React.useState('');
  const [date, setDate] = React.useState('');
  const [hash, setHash] = React.useState('');
  const [sign, setHashSign] = React.useState('');
  const [code, setCode] = React.useState('');
  const [signatarios, setSignatarios] = React.useState([]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    var formData = new FormData();
    if (selectedFile) {
      formData.append('Arquivo', selectedFile);
    }

    feedback('Aguarde, validando arquivo ...', 'loading');

    DocumentService.postDocumentValidate(formData)
      .then((result) => {
        var data = result;
        setName(data.nome);
        setDate(data.dataCriacao);
        setHash(data.hashOriginal);
        setHashSign(data.hashAssinado);
        setCode(data.code);
        setSignatarios(data.assinaturas);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        if (error instanceof ApiException) {
          feedback(error.message, 'error');
          setIsLoading(false);
        }
      });
  };

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const downloadDocument = () => {
    DocumentService.getDocumentFile(code)
      .then((result) => {
        const blob = new Blob([result], { type: 'application/pdf' });

        const pdfUrl = URL.createObjectURL(blob);
        window.open(pdfUrl, 'blank');
        setIsLoading(false);
      })
      .catch((error) => {
        if (error instanceof ApiException) {
          feedback(error.message, 'error');
          setIsLoading(false);
        }
      });
  };

  return (
    <PageLayout title='Validação de documento' alignNavigation='end'>
      <Grid
        container
        spacing={3}
        style={{
          border: '0',
          // minWidth: "100%",
        }}
      >
        {!name ? (
          <Grid item xs={12}>
            <Box
              maxWidth='650px'
              height='100%'
              display='flex'
              justifyContent='center'
              alignItems='center'
              flexDirection='column'
              sx={{
                border: '1px solid #CCC',
                margin: '0 auto',
                padding: '60px',
                borderRadius: '5px',
              }}
            >
              <Section title='Documento' margin>
                <h3>Validar documento a partir de um PDF</h3>
                <span style={{ fontSize: '13px', color: '#777777' }}>
                  Selecione um documento assinado pelo GVsign em .PDF.
                </span>
                <form onSubmit={handleSubmit}>
                  <input type='file' onChange={handleFileSelect} />
                  <br />
                  {!loading ? (
                    <Button
                      variant='contained'
                      style={{ marginTop: '10px' }}
                      type='submit'
                    >
                      Upload Arquivo
                    </Button>
                  ) : (
                    <Button
                      variant='contained'
                      style={{ marginTop: '10px' }}
                      disabled
                    >
                      Enviando Arquivo
                    </Button>
                  )}
                </form>
              </Section>
            </Box>
          </Grid>
        ) : (
          <Box
            width='650px'
            height='100%'
            sx={{
              border: '1px solid #CCC',
              margin: 'auto',
              marginTop: '100px',
              borderRadius: '5px',
            }}
            justifyContent='center'
            alignItems='center'
          >
            <Section title='Verificador de Autenticidade' margin>
              <h2 style={{ display: 'flex', alignItems: 'center' }}>
                {' '}
                <VerifiedIcon
                  style={{ width: '50px', height: '50px' }}
                  color='success'
                ></VerifiedIcon>
                &nbsp;Autenticidade reconhecida pelo GVsign.
              </h2>
              <span style={{ fontSize: '13px', color: '#777777' }}>
                O verificador de autenticidade do GVsign garante transparência,
                imutabilidade e irrevogabilidade para transações de assinatura
                eletrônica ocorridas na plataforma.
              </span>
              <h3>Dados do documento:</h3>
              <p>
                <b>Nome:</b> {name}
              </p>
              <p>
                <b>Data de criação:</b>{' '}
                {moment(date).format('DD/MM/YYYY hh:mm')}
              </p>
              <p>
                <b>Hash do documento original (SHA256):</b> {hash}
              </p>
              <p>
                <b>Hash do documento assinado (SHA256):</b> {sign}
              </p>
              <p>
                <b>Signatários:</b>
                <br />
                <ul>
                  {signatarios.map((name) => (
                    <li>{name}</li>
                  ))}
                </ul>
              </p>
              <Box
                sx={{ mb: 2 }}
                style={{
                  textAlign: 'center',
                  backgroundColor: '#fff',
                  padding: '10px',
                  marginTop: '20px',
                }}
              >
                <Button
                  variant='contained'
                  size='large'
                  sx={{ mt: 1, mr: 1 }}
                  onClick={downloadDocument}
                >
                  Baixar Original
                </Button>
              </Box>
            </Section>
          </Box>
        )}
      </Grid>
    </PageLayout>
  );
};
