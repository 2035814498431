import { Environment } from '../../../environment';
import { Api } from '../../axios-config/AxiosConfig';
import { ApiException } from '../ApiException';

export interface ISignatario {
  nome: string;
  tipoSignatario: string;
  status: string;
}

export enum StatusDocumento {
  NAO_ASSINADO = 'Não assinado',
  EM_ASSINATURA = 'Em assinatura',
  ASSINADO = 'Assinado',
  RECUSADO = 'Recusado',
  CANCELADO = 'Cancelado',
  VENCIDO = 'Vencido',
}

export enum StatusDocumentoSignatario {
  NAO_ASSINADO = 'Não assinado',
  ASSINADO = 'Assinado',
  RECUSADO = 'Recusado',
}

export interface IDocument {
  nome: string;
  tipoDocumento: string;
  signatarios: [ISignatario];
  status: StatusDocumento;
  statusSignatario: StatusDocumentoSignatario;
  hashArquivo: string;
  dataValidade: string;
}

export interface ISignatarioData {
  nome: string;
  cpf: string;
  rne: null;
  dataNascimento: string;
  email: string;
}

const getDocument = async (code: string): Promise<{ data: IDocument }> => {
  try {
    const { data } = await Api.get<IDocument>(
      `${Environment.API_GATEWAY}/api/v1/Documentos/${encodeURI(code)}`,
      {
        headers: {
          accept: 'text/plain',
          idTenant: 'gvdasa-cloud',
        },
      }
    );
    return {
      data,
    };
  } catch (error: any) {
    throw new ApiException(
      error.data.data.erro || 'Não foi possivel exibir os registros'
    );
  }
};

const getDocumentFile = async (code: string): Promise<any> => {
  try {
    const { data } = await Api.get(
      `${Environment.API_GATEWAY}/api/v1/Documentos/${code}/arquivo`,
      {
        responseType: 'arraybuffer',
        headers: {
          idTenant: 'gvdasa-cloud',
        },
      }
    );
    return data;
  } catch (error: any) {
    throw new ApiException(
      error?.data?.data?.erro || 'Não foi possível finalizar a requisição'
    );
  }
};

const postDocumentValidate = async (file: FormData): Promise<any> => {
  try {
    const { data } = await Api.post(
      `${Environment.API_GATEWAY}/api/v1/Documentos/validarArquivoAssinado`,
      file,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: 'text/plain',
        },
      }
    );
    return data;
  } catch (error: any) {
    throw new ApiException(
      error?.data?.data?.erro || 'Não foi possível finalizar a requisição'
    );
  }
};

const getDocumentoByCode = async (code: string): Promise<ISignatarioData> => {
  try {
    const { data } = await Api.get(`Documentos/${code}/autopreenchimento`, {
      headers: {
        idTenant: 'gvdasa-cloud',
      },
    });
    return data;
  } catch (error: any) {
    throw new ApiException(
      error?.data?.data?.erro ||
        'Não foi possível encontrar os dados do signatário'
    );
  }
};

export const DocumentService = {
  getDocument,
  getDocumentFile,
  postDocumentValidate,
  getDocumentoByCode,
};
