/**
 * Contém variáveis de ambiente para mais fácil utilização
 */
export const Environment = {
  /**
   * Configura a mensagem que será exibida quando uma listagem estiver vazia
   */
  LISTAGEM_VAZIA: 'Nenhum registro encontrado.',
  /**
   * Configura a mensagem que será exibida dentro dos campos de pesquisa
   */
  INPUT_DE_BUSCA: 'Pesquisar...',
  /**
   * Informa o ambiente atual. Pode ser "development", "test" ou "production"
   */
  REACT_APP_ENV: process.env.REACT_APP_ENV,
  /**
   * Informa se a aplicação está rodando em modo de produção
   */
  IS_PRODUCTION: process.env.REACT_APP_ENV === 'prod',
  /**
   * Informa se a aplicação está rodando em modo de Dev
   */
  IS_DEV: process.env.REACT_APP_ENV === 'dev',
  /**
   * Informa se a aplicação está rodando em modo de desenvolvimento
   */
  IS_DEVELOPMENT: process.env.REACT_APP_ENV === 'development',

  /**
   * Informa se a aplicação está rodando em modo de desenvolvimento
   */
  IS_HOMOLOG: process.env.REACT_APP_ENV === 'hml',
  /**
   * Url usada para o redirecionamento ao login service
   */
  API_GATEWAY:
    process.env.REACT_APP_ENV === 'prod'
      ? 'https://gvsignapi.gvdasa.com.br'
      : process.env.REACT_APP_ENV === 'hml'
      ? 'https://gvsignapi-hml.gvdasa.com.br'
      : process.env.REACT_APP_ENV === 'dev'
      ? 'https://gvsign-dev-wa.azurewebsites.net'
      : 'https://gvsign-dev-wa.azurewebsites.net',
  /**
   * Informa a url para a API
   */
  URL_API:
    process.env.REACT_APP_ENV === 'prod'
      ? 'https://gvsignapi.gvdasa.com.br/api/v1'
      : process.env.REACT_APP_ENV === 'hml'
      ? 'https://gvsignapi-hml.gvdasa.com.br/api/v1'
      : process.env.REACT_APP_ENV === 'dev'
      ? 'https://gvsignapi-dev.gvdasa.com.br/api/v1'
      : 'https://gvsignapi-dev.gvdasa.com.br/api/v1',

  URL_ORIGIN_DEBUG:
    process.env.REACT_APP_ENV === 'prod' ||
    process.env.REACT_APP_ENV === 'hml' ||
    process.env.REACT_APP_ENV === 'dev'
      ? ''
      : 'https://clientes-dev.gvdasa.com.br',

  APP_INSIGHTS_CONNECTION_STRING: '',
};
