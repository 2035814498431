import ExpandCircleDownRoundedIcon from '@mui/icons-material/ExpandCircleDownRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import { Box, Grid } from '@mui/material';
import { Section } from '../../../../shared/components';
import { memo } from 'react';
import { StatusDocumento } from '../../../../shared/services/api';

interface IStatusStepProps {
  status: StatusDocumento;
}

export const StatusStep = memo(({ status }: IStatusStepProps) => {
  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          margin: '10px 10px',
        }}
      >
        <Box
          maxWidth='600px'
          sx={{
            border: '1px solid #CCC',
            margin: '0 auto',
            borderRadius: '5px',
          }}
        >
          <Section margin>
            <h2 style={{ display: 'flex', alignItems: 'center', marginTop: 0 }}>
              {status === StatusDocumento.ASSINADO && (
                <>
                  <ExpandCircleDownRoundedIcon
                    sx={{ width: '50px', height: '50px' }}
                    color='success'
                  />
                  &nbsp;Assinatura finalizada
                </>
              )}
              {status === StatusDocumento.CANCELADO && (
                <>
                  <CancelRoundedIcon
                    sx={{ width: '50px', height: '50px' }}
                    color='error'
                  />
                  &nbsp;Documento cancelado
                </>
              )}
              {status === StatusDocumento.VENCIDO && (
                <>
                  <ErrorRoundedIcon
                    sx={{ width: '50px', height: '50px' }}
                    color='warning'
                  />
                  &nbsp;Documento expirado
                </>
              )}
            </h2>
            {status === StatusDocumento.ASSINADO && (
              <>
                <h3> Obrigado por assinar o documento.</h3>
                <p>
                  Assim que todos os signatários finalizarem a assinatura do
                  documento, você irá receber no seu e-mail o mesmo assinado.
                </p>
              </>
            )}
            {status === StatusDocumento.CANCELADO && (
              <>
                <h3>O documento não está mais disponível para assinatura.</h3>
                <p>
                  Caso necessite de esclarecimentos adicionais ou tenha
                  quaisquer dúvidas, favor entrar em contato com o remetente do
                  documento.
                </p>
              </>
            )}
            {status === StatusDocumento.VENCIDO && (
              <>
                <h3>O prazo para a assinatura do documento expirou.</h3>
                <p>
                  Caso necessite de esclarecimentos adicionais ou tenha
                  quaisquer dúvidas, favor entrar em contato com o remetente do
                  documento.
                </p>
              </>
            )}
          </Section>
        </Box>
      </Grid>
    </Grid>
  );
});
