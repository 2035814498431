import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Box, Skeleton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';

export interface IDocumentoProps {
  error: boolean;
  nome: string;
  dataValidade: string;
  expandirDocumento: boolean;
  handleExpandirDocumento: () => void;
}

export const AccordionDocumento: React.FC<IDocumentoProps> = React.memo(
  ({
    error,
    nome,
    dataValidade,
    expandirDocumento,
    handleExpandirDocumento,
  }) => {
    return (
      <Box
        width='100%'
        sx={{
          boxShadow: 'rgba(0, 0, 0, 0.15) 4px 4px 9px 0px',
        }}
      >
        <Accordion
          expanded={expandirDocumento}
          onChange={handleExpandirDocumento}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
            sx={{
              minHeight: '48px !important',
              '.MuiAccordionSummary-content': {
                margin: '12px !important',
              },
            }}
          >
            <Typography variant='h6' fontSize={18} marginLeft={'-12px'}>
              Documento
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ margin: '0px' }}>
            <Box display='flex' flexDirection='column' gap={0.5}>
              <h3 style={{ margin: '0px' }}>
                {error ? (
                  <></>
                ) : nome ? (
                  <Typography fontSize={14} fontWeight={700}>
                    {nome}
                  </Typography>
                ) : (
                  <Skeleton height='30px' />
                )}
              </h3>
              {dataValidade && (
                <Typography fontSize={14}>
                  <strong>Data limite: </strong>
                  {error ? (
                    <></>
                  ) : (
                    moment(dataValidade).format('DD/MM/YYYY') || <Skeleton />
                  )}
                </Typography>
              )}
              {!dataValidade && <Skeleton />}
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  }
);
