import React, {
  memo,
  useRef,
  Ref,
  useCallback,
  useEffect,
  useState,
} from 'react';
import * as pdfjs from 'pdfjs-dist';
import 'pdfjs-dist/build/pdf.worker';
import { Box, Button, IconButton, Typography } from '@mui/material';
import {
  Download,
  InfoOutlined,
  ZoomIn,
  ZoomOut,
  ZoomOutMap,
} from '@mui/icons-material';

pdfjs.GlobalWorkerOptions.workerSrc =
  'https://unpkg.com/pdfjs-dist@2.12.313/build/pdf.worker.min.js';

declare global {
  interface Window {
    pdfjsLib: typeof import('pdfjs-dist');
  }
}

export interface IPdf {
  pdf: string;
  onDownload: () => Promise<void>;
  downloading: boolean;
}

const INITIAL_SCALE_MOBILE = 0.7;
const INITIAL_SCALE_DESKTOP = 1.4;
const MAX_SCALE_DESKTOP = 1.7;
const MIN_SCALE_DESKTOP = 0.9;

export const GvPdfViewer: React.FC<IPdf> = memo(
  ({ pdf, onDownload, downloading }) => {
    const [firstLoad, setFirstLoad] = useState(true);
    const [isViewerVisible, setIsViewerVisible] = useState(false);
    const [isLoadingPages, setIsLoadingPages] = useState(false);
    const [pdfDocument, setPdfDocument] =
      useState<pdfjs.PDFDocumentProxy | null>(null);
    const containerViewerRef = useRef<Ref<unknown> | null>(null);

    const larguraDaTela =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    const isMobileDevice = larguraDaTela <= 600;
    const [scale, setScale] = useState(
      isMobileDevice ? INITIAL_SCALE_MOBILE : INITIAL_SCALE_DESKTOP
    );

    const renderAllPages = useCallback(async () => {
      if (pdfDocument) {
        const doc = pdfDocument;
        const container = document.getElementById('pdf-container');
        if (container) {
          setIsLoadingPages(true);
          container.innerHTML = '';
          for (let num = 1; num <= doc.numPages; num++) {
            await renderPage(num, scale, doc);
          }
          setIsLoadingPages(false);
        }
      }
    }, [pdfDocument, scale]);

    const renderPage = async (
      num: number,
      scale: number,
      pdfDocument: pdfjs.PDFDocumentProxy
    ) => {
      try {
        let outputScale = window.devicePixelRatio || 1;
        const page = await pdfDocument.getPage(num);
        const viewport = page.getViewport({ scale, rotation: 0 });
        const canvas = document.createElement('canvas');
        canvas.style.boxShadow =
          'rgba(0, 0, 0, 0.15) 2px 3px 20px -1px, rgba(0, 0, 0, 0.14) 1px 3px 2px -1px, rgba(0, 0, 0, 0.12) 3px 3px 10px -1px';
        canvas.id = `pdf-canvas-${num}`;
        const ctx = canvas.getContext('2d');
        canvas.width = Math.floor(viewport.width * outputScale);
        canvas.height = Math.floor(viewport.height * outputScale);
        canvas.style.width = Math.floor(viewport.width) + 'px';
        canvas.style.height = Math.floor(viewport.height) + 'px';
        const transform =
          outputScale !== 1
            ? [outputScale, 0, 0, outputScale, 0, 0]
            : undefined;

        const renderContext = {
          canvasContext: ctx as CanvasRenderingContext2D,
          transform: transform,
          viewport,
        };
        const renderTask = page.render(renderContext);
        await renderTask.promise;
        document.getElementById('pdf-container')?.appendChild(canvas);
      } catch (error) {
        console.error('Erro ao renderizar a página:', error);
      }
    };

    const increaseScale = useCallback(() => {
      setScale((prevScale) => Math.min(prevScale + 0.1, MAX_SCALE_DESKTOP));
    }, []);

    const decreaseScale = useCallback(() => {
      setScale((prevScale) => Math.max(prevScale - 0.1, MIN_SCALE_DESKTOP));
    }, []);

    const initialScale = useCallback(() => {
      setScale(isMobileDevice ? INITIAL_SCALE_MOBILE : INITIAL_SCALE_DESKTOP);
    }, [isMobileDevice]);

    const initializePdf = useCallback(async () => {
      try {
        const doc = await window.pdfjsLib.getDocument(pdf).promise;
        setPdfDocument(doc);
      } catch (error) {
        console.error('Erro ao renderizar o PDF:', error);
      } finally {
        setFirstLoad(false);
      }
    }, [pdf]);

    useEffect(() => {
      initializePdf();
    }, [initializePdf]);

    useEffect(() => {
      // * Valida caso o PDF já tenha sido carregado e possui algum conteúdo.
      // * Caso não tenha conteúdo, exibe uma mensagem para informar que o PDF não pode ser visualizado e um botão para baixar o PDF.
      if (pdf && !isLoadingPages && containerViewerRef?.current) {
        const childNodes = (
          containerViewerRef?.current as unknown as HTMLDivElement
        )?.childNodes;
        setIsViewerVisible(childNodes.length > 0);
      }
    }, [pdf, isLoadingPages, containerViewerRef]);

    useEffect(() => {
      renderAllPages();
    }, [renderAllPages]);

    return (
      <>
        <Box display='flex' flexDirection='column' alignItems='center'>
          {!isMobileDevice && pdf && isViewerVisible && !firstLoad && (
            <Box
              display='flex'
              alignItems='center'
              justifyContent='center'
              gap={1}
              margin={2}
            >
              <IconButton
                color='primary'
                onClick={decreaseScale}
                disabled={scale <= MIN_SCALE_DESKTOP || isLoadingPages}
              >
                <ZoomOut fontSize='medium' />
              </IconButton>
              <IconButton
                color='primary'
                onClick={increaseScale}
                disabled={scale >= MAX_SCALE_DESKTOP || isLoadingPages}
              >
                <ZoomIn fontSize='medium' />
              </IconButton>
              <IconButton
                color='primary'
                onClick={initialScale}
                disabled={
                  (!isMobileDevice && scale === INITIAL_SCALE_DESKTOP) ||
                  isLoadingPages
                }
              >
                <ZoomOutMap fontSize='medium' />
              </IconButton>
              <IconButton
                color='primary'
                onClick={onDownload}
                disabled={downloading}
              >
                <Download fontSize='medium' />
              </IconButton>
            </Box>
          )}
          <Box
            id='pdf-container'
            ref={containerViewerRef}
            sx={{
              overflowY: 'auto',
              overflowX: 'hidden',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '16px',

              'canvas:last-child': {
                marginBottom: '16px',
              },
            }}
          />
        </Box>
        {pdf && !isViewerVisible && !firstLoad && !isLoadingPages && (
          <Box
            sx={{
              maxWidth: isMobileDevice ? 600 : 'initial',
              margin: isMobileDevice ? '8px auto' : '32px',
              padding: '0 8px',
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Typography
              variant='h6'
              color='text.primary'
              fontSize={isMobileDevice ? 16 : 18}
              display='flex'
              alignItems='center'
              gap={1}
            >
              <InfoOutlined /> Não foi possível carregar a visualização do
              documento
            </Typography>
            <Typography
              variant='h6'
              color='text.secondary'
              fontSize={isMobileDevice ? 14 : 16}
            >
              A causa mais provável para este problema é a utilização de
              navegador desatualizado.
              <br />
              Recomendamos atualizar seu navegador ou baixar o documento no
              botão abaixo.
            </Typography>
            <Box>
              <Button
                startIcon={<Download />}
                variant='contained'
                color='primary'
                onClick={onDownload}
                size={isMobileDevice ? 'small' : 'medium'}
                disabled={downloading}
              >
                Baixar PDF
              </Button>
            </Box>
          </Box>
        )}
      </>
    );
  }
);
