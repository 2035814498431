import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Box, Divider, Skeleton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from 'react-simple-maps';
import { ISignatario } from '../../../shared/services/api';

export interface ISignatarioProps {
  error: boolean;
  loading: boolean;
  signatarios: ISignatario[];
  isGeolocationAvailable: boolean;
  isGeolocationEnabled: boolean;
  coords?: GeolocationCoordinates;
  coord: [number, number];
  expandirSignatario: boolean;
  handleExpandirSignatario: () => void;
  larguraDaTela: number;
}

export const AccordionSignatario: React.FC<ISignatarioProps> = React.memo(
  ({
    error,
    loading,
    signatarios,
    isGeolocationAvailable,
    isGeolocationEnabled,
    coords,
    coord,
    expandirSignatario,
    handleExpandirSignatario,
    larguraDaTela,
  }) => {
    return (
      <Box
        width='100%'
        sx={{
          boxShadow: 'rgba(0, 0, 0, 0.15) 4px 4px 9px 0px',
        }}
      >
        <Accordion
          expanded={expandirSignatario}
          onChange={handleExpandirSignatario}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
            sx={{
              minHeight: '48px !important',
              '.MuiAccordionSummary-content': {
                margin: '12px !important',
              },
            }}
          >
            <Typography variant='h6' fontSize={18} marginLeft={'-12px'}>
              Signatário(s)
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div
              style={{
                marginBottom: '10px',
              }}
            >
              {error && <></>}

              {!error && loading && <Skeleton />}

              {!error &&
                !loading &&
                signatarios.map((item, index) => (
                  <>
                    {index > 0 && <Divider sx={{ my: 1 }} />}
                    <Box display='flex' flexDirection='column' gap={0.5}>
                      <Typography fontSize={14}>
                        <strong>Nome:</strong> {item.nome}
                      </Typography>
                      <Typography fontSize={14}>
                        <strong>Tipo:</strong> {item.tipoSignatario}
                      </Typography>
                      <Typography fontSize={14}>
                        <strong>Status:</strong> {item.status}
                      </Typography>
                    </Box>
                  </>
                ))}
            </div>
            <div
              style={{
                marginTop: '10px',
                display: larguraDaTela <= 600 ? 'none' : 'block',
              }}
            >
              <Divider
                sx={{
                  marginTop: '6px',
                  marginBottom: '10px',
                }}
              />
              <Typography variant='h6' fontSize={18}>
                Localização
              </Typography>
              {error ? (
                <></>
              ) : loading ? (
                <Skeleton height={30} />
              ) : !isGeolocationAvailable ? (
                <div>Seu browser não suporta Geolocation</div>
              ) : !isGeolocationEnabled ? (
                <div>Geolocation não está habilitado</div>
              ) : coords ? (
                <ComposableMap
                  style={{
                    width: '100%',
                  }}
                  projection='geoAzimuthalEqualArea'
                  projectionConfig={{
                    rotate: [58, 20, 0],
                    scale: 600,
                  }}
                >
                  <Geographies
                    geography='/features.json'
                    fill='#D6D6DA'
                    stroke='#FFFFFF'
                    strokeWidth={0.5}
                  >
                    {({ geographies }) =>
                      geographies.map((geo) => (
                        <Geography key={geo.rsmKey} geography={geo} />
                      ))
                    }
                  </Geographies>
                  <Marker coordinates={coord}>
                    <circle r={12} fill='#F53' />
                  </Marker>
                </ComposableMap>
              ) : (
                <div>Getting the location data&hellip; </div>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  }
);
